import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useFetch from "../hooks/useFetch";

function Main() {
  const main = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalHeight, setModalHeight] = useState("100vh");
  const navigate = useNavigate();
  const { fetchData } = useFetch();

  const [passCode, setPasscode] = useState("");
  const [email, setEmail] = useState("");
  const [isCheckedAgeConfirm, setIsCheckedAgeConfirm] = useState(false);

  const [subscribeInfo, setSubscribeInfo] = useState({
    name: "",
    email: "",
    state: "",
  });

  useEffect(() => {
    updateHeight();
  }, [main.current]);

  const updateHeight = () => {
    setModalHeight(main.current?.offsetHeight);
  };

  const submitSubscribe = () => {
    if (!subscribeInfo.email || !subscribeInfo.state || !subscribeInfo.state) {
      toast.warn("You must fill out all fields to subscribe");
      return;
    } else if (formValidation("email", subscribeInfo.email) === false) {
      toast.warn("Email type is invalid");
    } else {
      fetchData({
        url: "https://splitaction.com/api/subscribe",
        body: { ...subscribeInfo },
        method: "POST",
      })
        .then((result) => {
          if (result.message === "success") {
            setShowModal(false);
            toast.success(
              `Subscribed successfully! Your Passcode is ${result?.passcode ? result?.passcode : "AllStar"
              }`,
              {
                autoClose: 20000, // duration in milliseconds, e.g., 5000ms = 5 seconds
              }
            );
          } else {
            toast.error(result.message);
          }
        })
        .catch((err) => toast.error(err.message));
    }
  };

  const submitPasscode = () => {
    if (!passCode) {
      toast.warn("Please enter passcode");
      return;
    } else if (!isCheckedAgeConfirm) {
      toast.warn("You should confirm that you are over 18 years old");
    } else if (!email) {
      toast.warn("Please enter a valid email.");
      return;
    }
    else {
      fetchData({
        url:
          "https://splitaction.com/api/passcodeLogin?passcode=" + passCode + "&email=" + email,
        method: "GET",
      })
        .then((result) => {
          if (result.data?.passcodeValid == true) {
            localStorage.setItem("saAuthToken", result.data.authtoken);
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem(
              "expireTime",
              new Date().getTime() + 86400 * 1000
            );
            toast.success("Passed successfully!");
            navigate("/data");
          } else {
            toast.error("Wrong Passcode!");
          }
        })
        .catch((err) => toast.error(err.message));
    }
  };

  const formValidation = (fieldName, value) => {
    let emailValid = false;
    let passwordValid = false;
    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        return emailValid !== null;
      case "password":
        passwordValid = value.length >= 8;
        return passwordValid;
      default:
        break;
    }
  };

  return (
    <div className="relative w-full h-fit">
      <div className={`${!showModal ? "hidden" : ""} flex justify-center`}>
        <div
          className="w-full backdrop-blur-[3px] bg-black/10 z-10 absolute top-0"
          style={{ height: modalHeight }}
          onClick={() => setShowModal(false)}
        ></div>
        <div className="sm:w-[550px] w-[360px] h-fit bg-[#333333] text-[#aaa] z-20 fixed top-36 px-6 py-4 rounded">
          <h1 className="text-3xl text-center mt-4 font-bold">SUBSCRIBE</h1>
          <p className="mt-3 pl-1">Name</p>
          <input
            type="text"
            value={subscribeInfo.name}
            className="placeholder-[#555] mt-1 bg-[#0f101a] h-full text-[#aaa] text-sm rounded border-[#0f101a] border focus:border-[#3cdf1f]
                focus:ring-blue-500 block w-full p-2.5 focus:outline-0"
            placeholder="Admin"
            onChange={(e) => {
              setSubscribeInfo({ ...subscribeInfo, name: e.target.value });
            }}
          />
          <p className="mt-3 pl-1">Email</p>
          <input
            type="text"
            value={subscribeInfo.email}
            className="placeholder-[#555] mt-1 bg-[#0f101a] h-full text-[#aaa] text-sm rounded border-[#0f101a] border focus:border-[#3cdf1f]
                focus:ring-blue-500 block w-full p-2.5 focus:outline-0"
            placeholder="admin@example.com"
            onChange={(e) => {
              setSubscribeInfo({ ...subscribeInfo, email: e.target.value });
            }}
          />
          <p className="mt-3 pl-1">State</p>
          <input
            type="text"
            value={subscribeInfo.state}
            className="placeholder-[#555] mt-1 bg-[#0f101a] h-full text-[#aaa] text-sm rounded border-[#0f101a] border focus:border-[#3cdf1f]
                focus:ring-blue-500 block w-full p-2.5 focus:outline-0"
            placeholder="..."
            onChange={(e) => {
              setSubscribeInfo({ ...subscribeInfo, state: e.target.value });
            }}
          />
          <button
            className="bg-[#3cdf1f] float-right mt-4 px-4 py-2 rounded hover:text-white text-[#333]"
            onClick={submitSubscribe}
          >
            Submit
          </button>
        </div>
      </div>

      <div
        className="w-full h-fit min-h-screen bg-black sm:py-4 sm:px-20 text-[#3cdf1f] relative"
        ref={main}
      >
        <div className="w-full p-4">
          <img src="/imgs/header.png" className="w-auto max-w-[90%]" alt="" />
        </div>
        <div className="bg-gradient-to-r from-[#3cdf1f] to-gray-900 h-10 w-full text-white rounded"></div>
        <div className="flex sm:py-6 py-4 w-full">
          <div className="w-60 sm:px-10">
            <img src="imgs/Football.png" alt="" />
            <img src="imgs/Basketball.png" className="sm:my-4" alt="" />
            <img src="imgs/Baseball.png" className="sm:my-4" alt="" />
            <img src="imgs/Hockey.PNG" className="sm:my-4" width={350} alt="" />
          </div>
          <div className="w-full">
            <p className="sm:text-3xl text-2xl mt-4 px-4 sm:px-10 loginMainLbl">
              Up Your Game On Sports Wagering
            </p>
            <p className="text-xl sm:mt-4 mt-2 px-4 sm:px-10">
              Free Wager Concensus Data
            </p>
            <div className="sm:p-12 p-6 text-[#aaa] text-2xl sm:mt-20 mt-10">
              <div className="w-full md:w-[550px] md:flex">
                <p className="text-lg text-left pr-1 whitespace-nowrap pt-1 loginInputLbl loginLbl">
                  Passcode
                </p>
                <input
                  type="text"
                  id="simple-search"
                  className="mb-3 bg-[#0f101a] h-full text-[#aaa] text-sm rounded border-[#0f101a] border focus:border-[#3cdf1f]
                focus:ring-blue-500 block w-full p-2.5 focus:outline-0"
                  placeholder="Passcode"
                  required
                  onChange={(e) => {
                    setPasscode(e.target.value);
                  }}
                />
              </div>
              <div className="w-full md:w-[550px] md:flex">
                <p className="text-lg text-left pr-1 whitespace-nowrap pt-1 loginInputLbl loginLbl">
                  Email   
                </p>
                <input
                  type="text"
                  id="simple-search"
                  className="mb-3 bg-[#0f101a] h-full text-[#aaa] text-sm rounded border-[#0f101a] border focus:border-[#3cdf1f]
                focus:ring-blue-500 block w-full p-2.5 focus:outline-0"
                  placeholder="Email"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <label className="custom-checkbox mt-4 sm:text-base text-sm loginLbl">
                By entering this website, you certify that you are over 18 and
                abide by the state's sports wagering laws.
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setIsCheckedAgeConfirm(e.target.checked);
                  }}
                />
                <span className="checkmark mt-[3px]"></span>
              </label>
              <button
                className="bg-transparent text-[#3cdf1f] font-bold py-3 w-52 border-[#3cdf1f] rounded-full
             hover:border-gray-300 sm:text-lg mt-8 text-base hover:text-white text-[#333]"
                style={{border: 'solid 1px'}}
                onClick={submitPasscode}
              >
                ENTER
              </button>
              <p className="text-base mt-2 loginLbl">
                No passcode?
                <button
                  className="text-[#3cdf1f] hover:text-[#30a010] font-[500] ml-2"
                  onClick={() => setShowModal(true)}
                >
                  Subscribe here
                </button>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full mt-10">
          <div className="bg-gradient-to-r from-[#3cdf1f] to-gray-900 h-10 w-full text-white rounded"></div>
          <p className="mt-1 text-sm text-yellow-200 px-4 text-center mb-2">
            @Published by SplitAction Copyright 2023 All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Main;
