import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import FilterModal from './FilterModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBaseballBall,
  faBasketball,
  faFootball,
  faHockeyPuck,
} from '@fortawesome/free-solid-svg-icons';
import classes from './Datatable.module.css';
import { faFileExcel, faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { Button, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

function Datatable(props) {
  const tableRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [currentMatchData, setCurrentMatchData] = useState([]);
  const [game, setGame] = useState("");

  const workbook = new Excel.Workbook();
  const workSheetName = 'Game Data';
  const workBookName = 'Exported Game Data';
  

  function roundToOneDecimal(number) {
    return (Math.round(number * 10) / 10);
  }
  const columns = [
    { header: 'Timing', key: 'timing' },
    { header: 'Team', key: 'team' },
    { header: 'Spread', key: 'spread' },
    { header: '% Bets', key: 'spread_bets' },
    { header: '% Handle', key: 'spread_handled' },
    { header: 'ML', key: 'moneyline' },
    { header: '% Bets', key: 'moneyline_bets' },
    { header: '% Handle', key: 'moneyline_handled' },
    { header: 'Total', key: 'total' },
    { header: '% Bets', key: 'total_bets' },
    { header: '% Handle', key: 'total_handled' },
    { header: 'Score', key: 'score' },
  ];

  useEffect(() => {
    if (game == "") {
      if(props.matches.nbaMatchData && props.matches.nbaMatchData.length > 0) {
        setGame("NBA");
      } else if(props.matches.nflMatchData && props.matches.nflMatchData.length > 0) {
        setGame("NFL");
      } else if(props.matches.nhlMatchData && props.matches.nhlMatchData.length > 0) {
        setGame("NHL");
      } else if(props.matches.ncaafMatchData && props.matches.ncaafMatchData.length > 0) {
        setGame("NCAAF");
      } else if(props.matches.ncaabMatchData && props.matches.ncaabMatchData.length > 0) {
        setGame("NCAAB");
      } else if(props.matches.mlbMatchData && props.matches.mlbMatchData.length > 0) {
        setGame("MLB");
      }
    }

    if (game == "NBA") {
      console.log(props.matches);
      setCurrentMatchData(props.matches.nbaMatchData);
    }

    else if (game == "NFL") {
      setCurrentMatchData(props.matches.nflMatchData);
    }

    else if (game == "NHL") {
      setCurrentMatchData(props.matches.nhlMatchData);
    }

    else if (game == "NCAAF") {
      setCurrentMatchData(props.matches.ncaafMatchData);
    }

    else if (game == "NCAAB") {
      setCurrentMatchData(props.matches.ncaabMatchData);
    }

    else if (game == "MLB") {
      setCurrentMatchData(props.matches.mlbMatchData);
    }

  }, [game, props.refresh, props.matches]);

  const saveExcel = async () => {
    try {
      const fileName = workBookName;

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: 'center' };
      });

      // loop through data and add each one to worksheet
      currentMatchData.map((match) =>
        match[Object.keys(match)[0]].map((match_detail, index) =>
          worksheet.addRow({
            timing: new Date(match_detail.GameTime).toDateString(),
            team: match_detail.TeamName,
            spread: match_detail.Spread,
            spread_bets: match_detail.SpreadBets,
            spread_handled: match_detail.SpreadHandled,
            moneyline: match_detail.Moneyline,
            moneyline_bets: match_detail.MoneylineBets,
            moneyline_handled: match_detail.MoneylineHandled,
            total: match_detail.Total,
            total_bets: match_detail.TotalBets,
            total_handled: match_detail.TotalHandled,
            score: match_detail.Score,
          })
        )
      );

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };
  // console.log(props.matches)
  return (
    <Fragment>
      <Container style={{ maxWidth: '100%' }}>
        <div style={{display: 'flex'}}>
          <Button
                type="button"
                variant="primary"
                onClick={() => props.setRefresh(!props.refresh)}
                style={{ border: 'solid 1px rgb(239,68,68)', borderRadius: 8888, display: 'flex', color: 'rgb(239,68,68)', textWrap: 'nowrap', alignItems: 'center', height: 34 }}
                className={`rounded-full bg-transparent hover:opacity-70`}
              >
                <FontAwesomeIcon icon={faRefresh} />
                <span className="mx-1 text-red-500">Refresh</span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{display: props.isRefreshing ? 'flex' : 'none'}}
                />
          </Button>
        </div>
        <Row>
          <div className="w-full flex-wrap flex justify-between gap-1 sm:gap-2 p-2 mb-2">
            {((!props.matches.nbaMatchData || props.matches.nbaMatchData.length == 0) &&
              <button
                className={`${classes.custom_button} rounded-full ${game !== 'NBA' ? 'sportButtonInactive' : 'sportButtonActive'}`}
                onClick={() => setGame('NBA')}
                style={{
                  color: game !== 'NBA' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faBasketball} />
                <span className="mx-1">NBA</span>
              </button>
            )}
            {((props.matches.nbaMatchData && props.matches.nbaMatchData.length > 0) &&
              <button
                className={`${classes.custom_button} ${classes.active_custom_btn} rounded-full ${game !== 'NBA' ? 'availableButtonInactive' : 'availableButtonActive'}`}
                onClick={() => setGame('NBA')}
                style={{
                  color: game !== 'NBA' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faBasketball} />
                <span className="mx-1">NBA</span>
              </button>
            )}

            {((!props.matches.nflMatchData || props.matches.nflMatchData.length == 0) &&
              <button
                className={`${classes.custom_button} rounded-full ${game !== 'NFL' ? 'sportButtonInactive' : 'sportButtonActive'}`}
                onClick={() => setGame('NFL')}
                style={{
                  color: game !== 'NFL' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faFootball} />
                <span className="mx-1">NFL</span>
              </button>
            )}
            {((props.matches.nflMatchData && props.matches.nflMatchData.length > 0) &&
              <button
                className={`${classes.custom_button} ${classes.active_custom_btn} rounded-full ${game !== 'NFL' ? 'availableButtonInactive' : 'availableButtonActive'}`}
                onClick={() => setGame('NFL')}
                style={{
                  color: game !== 'NFL' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faFootball} />
                <span className="mx-1">NFL</span>
              </button>
            )}

            {((!props.matches.nhlMatchData || props.matches.nhlMatchData.length == 0) &&
              <button
                className={`${classes.custom_button} rounded-full ${game !== 'NHL' ? 'sportButtonInactive' : 'sportButtonActive'}`}
                onClick={() => setGame('NHL')}
                style={{
                  color: game !== 'NHL' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faHockeyPuck} />
                <span className="mx-1">NHL</span>
              </button>
            )}
            {((props.matches.nhlMatchData && props.matches.nhlMatchData.length > 0) &&
            <button
              className={`${classes.custom_button} ${classes.active_custom_btn} rounded-full ${game !== 'NHL' ? 'availableButtonInactive' : 'availableButtonActive'}`}
              onClick={() => setGame('NHL')}
              style={{
                color: game !== 'NHL' ? 'black' : 'white'
              }}
            >
              <FontAwesomeIcon icon={faHockeyPuck} />
              <span className="mx-1">NHL</span>
            </button>
            )}

            {((!props.matches.ncaafMatchData || props.matches.ncaafMatchData.length == 0) &&
              <button
                className={`${classes.custom_button} rounded-full ${game !== 'NCAAF' ? 'sportButtonInactive' : 'sportButtonActive'}`}
                onClick={() => setGame('NCAAF')}
                style={{
                  color: game !== 'NCAAF' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faFootball}/>
                <span className="mx-1">NCAAF</span>
              </button>
            )}
            {((props.matches.ncaafMatchData && props.matches.ncaafMatchData.length > 0) &&
            <button
              className={`${classes.custom_button} ${classes.active_custom_btn} rounded-full ${game !== 'NCAAF' ? 'availableButtonInactive' : 'availableButtonActive'}`}
              onClick={() => setGame('NCAAF')}
              style={{
                color: game !== 'NCAAF' ? 'black' : 'white'
              }}
            >
              <FontAwesomeIcon icon={faFootball} />
              <span className="mx-1">NCAAF</span>
            </button>
            )}

            {((!props.matches.ncaabMatchData || props.matches.ncaabMatchData.length == 0) &&
              <button
                className={`${classes.custom_button} rounded-full ${game !== 'NCAAB' ? 'sportButtonInactive' : 'sportButtonActive'}`}
                onClick={() => setGame('NCAAB')}
                style={{
                  color: game !== 'NCAAB' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faBasketball} />
                <span className="mx-1">NCAAB</span>
              </button>
            )}
            {((props.matches.ncaabMatchData && props.matches.ncaabMatchData.length > 0) &&
              <button
                className={`${classes.custom_button} ${classes.active_custom_btn} rounded-full ${game !== 'NCAAB' ? 'availableButtonInactive' : 'availableButtonActive'}`}
                onClick={() => setGame('NCAAB')}
                style={{
                  color: game !== 'NCAAB' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faBasketball} />
                <span className="mx-1">NCAAB</span>
              </button>
            )}

            {((!props.matches.mlbMatchData || props.matches.mlbMatchData.length == 0) &&
              <button
                className={`${classes.custom_button} rounded-full ${game !== 'MLB' ? 'sportButtonInactive' : 'sportButtonActive'}`}
                onClick={() => setGame('MLB')}
                style={{
                  color: game !== 'MLB' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faBaseballBall} />
                <span className="mx-1">MLB</span>
              </button>
            )}
            {((props.matches.mlbMatchData && props.matches.mlbMatchData.length > 0) &&
              <button
                className={`${classes.custom_button} ${classes.active_custom_btn} rounded-full ${game !== 'MLB' ? 'availableButtonInactive' : 'availableButtonActive'}`}
                onClick={() => setGame('MLB')}
                style={{
                  color: game !== 'MLB' ? 'black' : 'white'
                }}
              >
                <FontAwesomeIcon icon={faBaseballBall} />
                <span className="mx-1">MLB</span>
              </button>
            )}

            <Button
              type="button"
              variant="primary"
              onClick={() => setModalShow(true)}
              style={{ border: 'solid 1px rgb(60,223,31)', borderRadius: 8888, display: 'flex', color: 'rgb(60,223,31)', textWrap: 'nowrap', alignItems: 'center', height: 34 }}
              className={`rounded-full bg-transparent hover:opacity-70`}
            >
              <FontAwesomeIcon icon={faFilter} />
              <span className="mx-1">Filter data</span>
            </Button>
            {/* <Button
              className={`rounded-full h-[32px] flex items-center px-2 py-0 justify-center`}
              onClick={saveExcel}
            >
              <FontAwesomeIcon icon={faFileExcel} />
            </Button> */}

          </div>
          <div className='p-0'>
            <FilterModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              applyFilters={props.applyFilters}
            />

            <div className="table-responsive bg-[#36454F] overflow-auto">
              <Table
                hover
                className={`sm:text-[16px] text-sm fs-8 rounded w-full mb-0 ${classes.custom_table}`}
                ref={tableRef}
              >
                <thead className='sm:text-base text-sm'>
                  <tr>
                    <th className='text-center'>Game Date</th>
                    <th className='text-center'>Team</th>
                    <th className='text-center border-l border-l-[#aaa]'>Spread</th>
                    <th className='text-center'><div>%</div>Bets</th>
                    <th className='text-center'><div>%</div>Handle</th>
                    <th className='text-center border-l border-l-[#aaa]'>ML</th>
                    <th className='text-center'><div>%</div>Bets</th>
                    <th className='text-center'><div>%</div>Handle</th>
                    <th className='text-center border-l border-l-[#aaa]'>Total</th>
                    <th className='text-center'><div>%</div>Bets</th>
                    <th className='text-center'><div>%</div>Handle</th>
                    <th className='text-center'>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {currentMatchData === null || currentMatchData === undefined || currentMatchData.length == 0
                    ? (<div style={{ color: 'white', padding: '1rem' }}>Coming soon.</div>)
                    : (currentMatchData.map((match) =>
                      match[Object.keys(match)[0]].map((match_detail, index) => (
                        match[Object.keys(match)[0]].length > 1 &&
                        <tr
                          key={match_detail.MatchId + '_' + index}
                          style={{
                            borderBottom: index === 1 ? '0.2rem solid #2d343b' : '',
                          }}
                        >
                          {index === 0 && (
                            <td
                              className="text-center relative sm:text-base text-sm"
                              rowSpan={2}
                            >
                              <div className='bottom-0 px-1 pb-1'>
                                {new Date(match_detail.GameTime)
                                  .toUTCString()
                                  .slice(0, 3)}
                                <br />
                                {new Date(match_detail.GameTime)
                                  .toUTCString()
                                  .slice(0, -13).replace(new Date(match_detail.GameTime)
                                    .toUTCString()
                                    .slice(0, 4), "")}
                              </div>
                            </td>
                          )}
                          <td className={classes.team_cell} colSpan={index > 1 && 2}>
                           {(game !== 'NCAAB' && game !== 'NCAAF')
                              && match_detail.TeamLogo
                              && <div
                                className={classes.team_logo}
                                style={{
                                  backgroundSize: 'cover',
                                  backgroundImage: `url(${match_detail.TeamLogo
                                    ? match_detail.TeamLogo
                                    : 'assets/images/blank-placeholder.jpg'
                                    })`,
                                }}
                              ></div>}
                            <div 
                            className='text-center sm:text-base text-sm' 
                            style={{
                              display: 'inline-block',
                              paddingLeft: '1rem'
                            }}><b>{game !== 'NCAAF' ? match_detail.TeamCode : match_detail.TeamCodeDisplay}</b></div>
                          </td>
                          <td className="text-center sm:text-base text-sm fw-bold border-l border-l-[#373c43] " style={{ color: '#3cdf1f', borderBottom: '0.1rem solid rgb(52 60 67)' }}>
                            {match_detail.Spread == null ? '0.0' : roundToOneDecimal(match_detail.Spread)}
                          </td>
                          <td className="text-center sm:text-base text-sm" style={{ borderBottom: '0.1rem solid rgb(52 60 67)' }}>{match_detail.SpreadBets == null ? '0' : match_detail.SpreadBets}</td>
                          <td className="text-center sm:text-base text-sm" style={{ borderBottom: '0.1rem solid rgb(52 60 67)' }}>{match_detail.SpreadHandled == null ? '0' : match_detail.SpreadHandled}</td>
                          <td className="text-center sm:text-base text-sm fw-bold border-l border-l-[#373c43]" style={{ color: '#3cdf1f', borderBottom: '0.1rem solid rgb(52 60 67)' }}>{match_detail.Moneyline == null ? '0' : match_detail.Moneyline}</td>
                          <td className="text-center sm:text-base text-sm" style={{ borderBottom: '0.1rem solid rgb(52 60 67)' }}>{match_detail.MoneylineBets == null ? '0' : match_detail.MoneylineBets}</td>
                          <td className="text-center sm:text-base text-sm" style={{ borderBottom: '0.1rem solid rgb(52 60 67)' }}>{match_detail.MoneylineHandled == null ? '0' : match_detail.MoneylineHandled}</td>
                          <td className="text-center sm:text-base text-sm fw-bold border-l border-l-[#373c43]" style={{ color: '#3cdf1f', borderBottom: '0.1rem solid rgb(52 60 67)' }}>{match_detail.Total == null ? '0' : match_detail.Total}</td>
                          <td className="text-center sm:text-base text-sm" style={{ borderBottom: '0.1rem solid rgb(52 60 67)' }}>{match_detail.TotalBets == null ? '0' : match_detail.TotalBets}</td>
                          <td className="text-center sm:text-base text-sm" style={{ borderBottom: '0.1rem solid rgb(52 60 67)' }}>{match_detail.TotalHandled == null ? '0' : match_detail.TotalHandled}</td>
                          <td className="text-center sm:text-base text-sm fw-bold border-l border-l-[#373c43]" style={{borderBottom: '0.1rem solid rgb(52 60 67)'}}>{match_detail.Score == null ? '0' : match_detail.Score}</td>
                        </tr>
                      )
                      ))
                    )
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </Container>

    </Fragment>
  );
}

export default Datatable;