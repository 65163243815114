import React from 'react';
import { Navigate } from 'react-router-dom';

const withAuth = (Component) => {
  const AuthRoute = (props) => {
    // const isAuthenticated = localStorage.getItem('isAuthenticated');
    // const isExpired = (Number.parseInt(localStorage.getItem('expireTime')) < new Date().getTime())
    
    // ========
    const isAuthenticated = true;
    const isExpired = false;

    localStorage.setItem("saAuthToken", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im1hcmlvQG1hcGFkZXZlbG9wbWVudC5jb20iLCJwYXNzd29yZCI6IkFsbFN0YXIiLCJyb2xlIjoidXNlciIsImlhdCI6MTY5MjA2NzQxMSwiZXhwIjoxNjkyMDc0NjExfQ.poSiPUn0wxNcwQNjgyJ7y916-5q4YEiHF4qLzusJsIY");
    localStorage.setItem("isAuthenticated", true);
    localStorage.setItem(
      "expireTime",
      new Date().getTime() + 86400 * 1000
    );
    // ==========

    if (isExpired === true) {
      localStorage.clear();
    }
    if (isAuthenticated && !isExpired) {
      return <Component {...props} />;
    } else {
      return <Navigate to="/" />;
    }
  };

  return AuthRoute;
};

export default withAuth;