import React, { useState, useRef, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import config from "../data.config.json";
import Datatable from "../components/Datatable";
import useFetch from "../hooks/useFetch";
import { useContext } from "react";
import { FilterContext } from "../context/FilterContext";

function DataPage() {
  const [matchData, setMatchData] = useState({});
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { fetchData } = useFetch();
  const { filters } = useContext(FilterContext);

  const main = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const configData = config.DataPage;
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    setIsRefreshing(true);
    fetchData({
      url: "https://splitaction.com/api/getAllMatches",
      method: "GET",
    }).then((result) => {
      if (result.status) {
        setMatchData(result.data);
        setUnfilteredData(result.data);
        setIsRefreshing(false);
      }
    });

  }, [refresh]);

  const applyFilters = () => {
    let filteredNbaData = unfilteredData.nbaMatchData.filter((match) => {
      let show_match = false;
      match[Object.keys(match)[0]].forEach((match_detail) => {
        show_match =
          show_match ||
          (filters.spread.bets.min <= match_detail.SpreadBets &&
            match_detail.SpreadBets <= filters.spread.bets.max &&
            filters.spread.handled.min <= match_detail.SpreadHandled &&
            match_detail.SpreadHandled <= filters.spread.handled.max &&
            filters.moneyline.bets.min <= match_detail.MoneylineBets &&
            match_detail.MoneylineBets <= filters.moneyline.bets.max &&
            filters.moneyline.handled.min <= match_detail.MoneylineHandled &&
            match_detail.MoneylineHandled <= filters.moneyline.handled.max &&
            filters.total.bets.min <= match_detail.TotalBets &&
            match_detail.TotalBets <= filters.total.bets.max &&
            filters.total.handled.min <= match_detail.TotalHandled &&
            match_detail.TotalHandled <= filters.total.handled.max);
      });
      return show_match;
    });

    let filteredNflData = unfilteredData.nflMatchData.filter((match) => {
      let show_match = false;
      match[Object.keys(match)[0]].forEach((match_detail) => {
        show_match =
          show_match ||
          (filters.spread.bets.min <= match_detail.SpreadBets &&
            match_detail.SpreadBets <= filters.spread.bets.max &&
            filters.spread.handled.min <= match_detail.SpreadHandled &&
            match_detail.SpreadHandled <= filters.spread.handled.max &&
            filters.moneyline.bets.min <= match_detail.MoneylineBets &&
            match_detail.MoneylineBets <= filters.moneyline.bets.max &&
            filters.moneyline.handled.min <= match_detail.MoneylineHandled &&
            match_detail.MoneylineHandled <= filters.moneyline.handled.max &&
            filters.total.bets.min <= match_detail.TotalBets &&
            match_detail.TotalBets <= filters.total.bets.max &&
            filters.total.handled.min <= match_detail.TotalHandled &&
            match_detail.TotalHandled <= filters.total.handled.max);
      });
      return show_match;
    });


    let filteredNhlData = unfilteredData.nhlMatchData.filter((match) => {
      let show_match = false;
      match[Object.keys(match)[0]].forEach((match_detail) => {
        show_match =
          show_match ||
          (filters.spread.bets.min <= match_detail.SpreadBets &&
            match_detail.SpreadBets <= filters.spread.bets.max &&
            filters.spread.handled.min <= match_detail.SpreadHandled &&
            match_detail.SpreadHandled <= filters.spread.handled.max &&
            filters.moneyline.bets.min <= match_detail.MoneylineBets &&
            match_detail.MoneylineBets <= filters.moneyline.bets.max &&
            filters.moneyline.handled.min <= match_detail.MoneylineHandled &&
            match_detail.MoneylineHandled <= filters.moneyline.handled.max &&
            filters.total.bets.min <= match_detail.TotalBets &&
            match_detail.TotalBets <= filters.total.bets.max &&
            filters.total.handled.min <= match_detail.TotalHandled &&
            match_detail.TotalHandled <= filters.total.handled.max);
      });
      return show_match;
    });

    let filteredNcaafData = unfilteredData.ncaafMatchData.filter((match) => {
      let show_match = false;
      match[Object.keys(match)[0]].forEach((match_detail) => {
        show_match =
          show_match ||
          (filters.spread.bets.min <= match_detail.SpreadBets &&
            match_detail.SpreadBets <= filters.spread.bets.max &&
            filters.spread.handled.min <= match_detail.SpreadHandled &&
            match_detail.SpreadHandled <= filters.spread.handled.max &&
            filters.moneyline.bets.min <= match_detail.MoneylineBets &&
            match_detail.MoneylineBets <= filters.moneyline.bets.max &&
            filters.moneyline.handled.min <= match_detail.MoneylineHandled &&
            match_detail.MoneylineHandled <= filters.moneyline.handled.max &&
            filters.total.bets.min <= match_detail.TotalBets &&
            match_detail.TotalBets <= filters.total.bets.max &&
            filters.total.handled.min <= match_detail.TotalHandled &&
            match_detail.TotalHandled <= filters.total.handled.max);
      });
      return show_match;
    });

    let filteredNcaabData = unfilteredData.ncaabMatchData.filter((match) => {
      let show_match = false;
      match[Object.keys(match)[0]].forEach((match_detail) => {
        show_match =
          show_match ||
          (filters.spread.bets.min <= match_detail.SpreadBets &&
            match_detail.SpreadBets <= filters.spread.bets.max &&
            filters.spread.handled.min <= match_detail.SpreadHandled &&
            match_detail.SpreadHandled <= filters.spread.handled.max &&
            filters.moneyline.bets.min <= match_detail.MoneylineBets &&
            match_detail.MoneylineBets <= filters.moneyline.bets.max &&
            filters.moneyline.handled.min <= match_detail.MoneylineHandled &&
            match_detail.MoneylineHandled <= filters.moneyline.handled.max &&
            filters.total.bets.min <= match_detail.TotalBets &&
            match_detail.TotalBets <= filters.total.bets.max &&
            filters.total.handled.min <= match_detail.TotalHandled &&
            match_detail.TotalHandled <= filters.total.handled.max);
      });
      return show_match;
    });

    let filteredMlbData = unfilteredData.mlbMatchData.filter((match) => {
      let show_match = false;
      match[Object.keys(match)[0]].forEach((match_detail) => {
        show_match =
          show_match ||
          (filters.spread.bets.min <= match_detail.SpreadBets &&
            match_detail.SpreadBets <= filters.spread.bets.max &&
            filters.spread.handled.min <= match_detail.SpreadHandled &&
            match_detail.SpreadHandled <= filters.spread.handled.max &&
            filters.moneyline.bets.min <= match_detail.MoneylineBets &&
            match_detail.MoneylineBets <= filters.moneyline.bets.max &&
            filters.moneyline.handled.min <= match_detail.MoneylineHandled &&
            match_detail.MoneylineHandled <= filters.moneyline.handled.max &&
            filters.total.bets.min <= match_detail.TotalBets &&
            match_detail.TotalBets <= filters.total.bets.max &&
            filters.total.handled.min <= match_detail.TotalHandled &&
            match_detail.TotalHandled <= filters.total.handled.max);
      });
      return show_match;
    });

    setMatchData({
      nbaMatchData: filteredNbaData,
      nflMatchData: filteredNflData,
      nhlMatchData: filteredNhlData,
      ncaafMatchData: filteredNcaafData,
      ncaabMatchData: filteredNcaabData,
      mlbMatchData: filteredMlbData,
    });
  };

  return (
    <div className="relative w-full h-fit">
      <div
        className="w-full h-fit min-h-screen bg-black sm:pt-4 text-[#3cdf1f] relative"
        ref={main}
      >
        <div className="">
          <div className="w-full p-4">
            <img src="/imgs/logo.jpg" className="w-auto sm:max-h-[140px]" alt="" />
          </div>
          <div className="bg-gradient-to-r from-[#3cdf1f] to-gray-900 h-10 w-full text-white rounded justify-end flex">
            <button
              className="mr-4 font-semibold hover:opacity-70"
              onClick={() => {
                navigate("/");
              }}
              style={{ color: window.location.pathname.indexOf('') > -1 ? '#3cdf1f' : '#888' }}
            >
              SPLITS DATA
            </button>
            <button
              className="mr-4 font-semibold hover:opacity-70"
              onClick={() => {
                navigate("/htu");
              }}
              style={{ color: window.location.pathname.indexOf('htu') > -1 ? '#3cdf1f' : '#888' }}
            >
              HOW TO USE
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center h-full">
            <span class="loader top-80"></span>
          </div>
        ) : (
          <div className="lg:flex sm:py-8 py-4">
            <div className="lg:w-[calc(100%-370px)] md:flex">
              <div className="md:w-[28%] h-fit sm:mx-6 mx-2 my-2 text-[15px] sm:text-[17px] text-[#888] mt-2 sm:mt-8">
                <div className="w-full px-3 py-4 bg-[#1a1a1a]">
                  <div className="text-[#3cdf1f]">
                    <p className="sm:text-3xl text-xl font-semibold">Are you leaving money on the table?</p>
                    Having multiple sportsbooks is not just a strategy—it’s a necessity. By shopping for the best spreads and payouts, you can experience a significant boost to your bottom line. Imagine increasing your earnings by 4% to 11% or more just by being smart about where you place your bets.
                  </div>
                  <br />
                  <div className="text-[#3cdf1f]">
                    <p className="sm:text-2xl text-lg font-semibold">Maximize Your Profits, Minimize Your Risk</p>
                    Stop giving your hard-earned money away to less competitive sportsbooks. With multiple accounts, you can quickly find the best odds and limit your risk. Don’t just take our word for it—verify it yourself and see the difference.
                  </div>
                  <br />
                  <div className="text-[#3cdf1f]">
                    <p className="sm:text-2xl text-lg font-semibold">Get Started in Minutes</p>
                    You’re just minutes away from participating in the action. Opening and funding your accounts with&nbsp;
                    <a className="text-red-500" href="https://bit.ly/BetOnlineSA" rel="noreferrer" target="_blank">BetOnline</a>
                    &nbsp;and&nbsp;
                    <a className="text-red-500" href="https://bit.ly/MyBookieSA" rel="noreferrer" target="_blank">MyBookie.</a>
                    &nbsp;is fast, secure, and easy. In just 10 minutes, you can be ready to shop for the best spreads and payouts, ensuring that every bet you place is optimized for maximum returns.
                  </div>
                  <br />
                  <div className="text-[#3cdf1f]">
                    <p className="sm:text-2xl text-lg font-semibold">Ready to Win More?</p>
                    Don’t wait—start now and take control of your betting strategy. Open your accounts, compare the odds, and watch your winnings soar.
                  </div>
                </div>
                <div className="w-full mt-4">
                  <a
                    href="https://record.betonlineaffiliates.ag/_-upPomnA17woAmwrkE6KlGNd7ZgqdRLk/1/"
                    target="_blank"
                    className="w-full"
                    rel="noreferrer"
                  >
                    <img src="/imgs/BetOnlineBaseball.jpg" alt="split action" className="mx-auto my-2" />
                  </a>
                  <a
                    href="https://record.webpartners.co/_HFx3XJRH2msWqcfzuvZcQGNd7ZgqdRLk/1/"
                    target="_blank"
                    className="w-full mt-2"
                    rel="noreferrer"
                  >
                    <img src="/imgs/MyBookie.jpg" alt="split action" className="mx-auto" />
                  </a>
                </div>
              </div>
              <div className="md:w-[72%] mx-2">
                <div className="p-2 mt-4">
                  <p className="font-[500] sm:text-3xl text-xl">
                    Up Your Game on Sports Wagering
                  </p>
                  <p className="text-[15px] sm:text-[17px] mt-2">
                    What direction is the crowd going? Where is the “sharp” money? SplitAction provides free, easily understandable,
                    and valuable information to help you make smarter wagers and improve payouts.
                  </p>
                  <p className="text-[15px] sm:text-[17px] mt-2 mb-0">
                    SplitAction merges consensus data from several sources to provide a more objective perspective on betting splits.
                    Obtaining data from a single source can be biased based upon marketing and / or states of operation.
                  </p>
                </div>
                {/* <a
                  href={configData?.middleBanners[0].href}
                  target="_blank"
                  className="flex text-center px-2 justify-center h-fit my-2 rounded-lg w-full cursor-pointer relative"
                >
                  <img
                    src={configData?.middleBanners[0].src}
                    className=""
                    alt=""
                  />
                </a>
                <a
                  href={configData?.middleBanners[1].href}
                  target="_blank"
                  className="flex text-center px-2 justify-center h-fit mt-4 rounded-lg min-w-56 w-full cursor-pointer relative"
                >
                  <img
                    src={configData?.middleBanners[1].src}
                    className="rounded sm:rounded-2xl"
                    alt=""
                  />
                </a> */}
                <div className="p-2 mb-4 rounded text-sm sm:text-base text-justify">
                  Profitable sports bettors look for good value opportunities in wagers and use a variety of data to make their decisions.
                  SplitAction provides consensus data in a clean table to filter and download for future analysis while other sites present this data in a confusing format.
                  Use this data as you deem appropriate with your other analysis. Learn more <NavLink to="/htu" className="text-red-500">here</NavLink>
                </div>
                <Datatable
                  matches={matchData}
                  applyFilters={applyFilters}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  isRefreshing={isRefreshing}
                />
              </div>
            </div>
            <div className="sm-full md:w-3/4 float-right lg:w-[370px] lg:mx-6 lg:block grid lg:grid-cols-1 grid-cols-2 text-[#aaa] mt-8 relative z-[10]">
              {configData?.rightBanners?.map((val, ind) => (
                <div className="sm:h-[145px] sm:flex items-center justify-center" key={ind}>
                  <a
                    href={val.href}
                    target="_blank"
                    className="sm:flex items-center mx-2 h-fit rounded-lg min-w-56 w-full cursor-pointer md:flex no-underline"
                  >
                    <img
                      src={val?.src}
                      className="rounded-2xl aspect-square mx-auto sm:w-[125px] sm:h-[125px]"
                      alt=""
                    />
                    <p className="p-2 mb-0 md:p-4 text-xs sm:text-sm text-justify text-[#aaa] sm:w-[calc(100%-130px)]">
                      {val?.text}
                    </p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        {!isLoading && (
          <>
            <div className="w-full justify-center text-white text-justify">
              <div className="p-4 md:w-1/2 mx-auto">
                <p className="text-2xl sm:text-3xl text-center opacity-0">.</p>
                <p className="border border-[#3cdf1f] p-3 text-[13px] sm:text-[15px]">
                  <p className="text-xl sm:text-2xl text-center">Disclaimer</p>
                  SplitAction is not a sportsbook operator but rather an informational site regarding sports betting for entertainment purposes.
                  Sports wagering laws vary by situs and change continuously.
                  SplitAction does not provide any information as to the legalities in your location.
                  Users of data and advertising links are strongly suggested to make their own inquiry into the legality of using this information.
                  SplitAction assumes no liability for the usage of the data, nor the products and services offered its advertising links.
                  The vetted vendors of the products and services offered by SplitAction are proven to be reliable and trustworthy.
                  SplitAction may be supported by the vendors. Vendors will periodically change.
                </p>
              </div>
            </div>
            <div className="bg-gradient-to-r from-[#3cdf1f] to-gray-900 h-10 w-full text-white rounded mt-5"></div>
            <div className="mt-5">
              <div className="w-full justify-center text-white from-[#3cdf1f] to-gray-900 text-white rounded">
                <div className="sm:text-base py-2 text-sm p-4 mx-auto text-left sm:w-2/3 text-yellow-200 text-center">
                  <p>Wager responsibly.</p>
                  Any tips, suggestions or comments for SplitAction, email us at{" "}
                  <a href="#" className="text-red-500">
                    support@splitaction.com
                  </a>
                  <p className="mt-4">Gambling Problem?? 1-800-GAMBLER.</p>
                  <p>
                    Published by SplitAction @Copyright2023 All rights
                    reserved.
                  </p>
                  <p>
                    Designated branding and trademarks by SplitAction and its
                    vendors are the property of their respective owners.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DataPage;
