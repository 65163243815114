import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from '../data.config.json';

function HowToUse() {
  const main = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const configData = config.HowToUse;
  const navigate = useNavigate();

  return (
    <div className="relative w-full h-fit">
      <div
        className="w-full h-fit min-h-screen bg-black sm:pt-4 text-[#3cdf1f] relative"
        ref={main}
      >
        <div className="">
          {/* <div className="flex justify-between">
          <h1 className="sm:text-5xl text-3xl font-bold my-2 sm:pl-8 pl-4 py-2 cursor-pointer"
            onClick={() => { navigate('/') }} >Split Action</h1>
        </div> */}
          <div className="w-full p-4">
            <img src="/imgs/logo.jpg" className="w-auto sm:max-h-[140px]" alt="" />
          </div>
          <div className="bg-gradient-to-r from-[#3cdf1f] to-gray-900 h-10 w-full text-white rounded justify-end flex">
            <button
              className="mr-4 font-semibold hover:opacity-70"
              onClick={() => {
                navigate("/");
              }}
              style={{ color: window.location.pathname.indexOf('data') > -1 ? '#3cdf1f' : '#888' }}
            >
              SPLITS DATA
            </button>
            <button
              className="mr-4 font-semibold hover:opacity-70"
              onClick={() => {
                navigate("/htu");
              }}
              style={{ color: window.location.pathname.indexOf('htu') > -1 ? '#3cdf1f' : '#888' }}
            >
              HOW TO USE
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center h-full">
            <span class="loader top-80"></span>
          </div>
        ) : (
          <div className="lg:flex sm:py-8 py-4">
            <div className="lg:w-[calc(100%-370px)] md:flex">
              <div className="md:w-[28%] h-fit sm:mx-6 mx-2 my-2 text-[15px] sm:text-[17px] text-[#888] mt-2 sm:mt-8">
                <div className="w-full px-3 py-4 bg-[#1a1a1a]">
                  <div className="text-[#3cdf1f]">
                    <p className="sm:text-3xl text-xl font-semibold">Are you leaving money on the table?</p>
                    Having multiple sportsbooks is not just a strategy—it’s a necessity. By shopping for the best spreads and payouts, you can experience a significant boost to your bottom line. Imagine increasing your earnings by 4% to 11% or more just by being smart about where you place your bets.
                  </div>
                  <br />
                  <div className="text-[#3cdf1f]">
                    <p className="sm:text-2xl text-lg font-semibold">Maximize Your Profits, Minimize Your Risk</p>
                    Stop giving your hard-earned money away to less competitive sportsbooks. With multiple accounts, you can quickly find the best odds and limit your risk. Don’t just take our word for it—verify it yourself and see the difference.
                  </div>
                  <br />
                  <div className="text-[#3cdf1f]">
                    <p className="sm:text-2xl text-lg font-semibold">Get Started in Minutes</p>
                    You’re just minutes away from participating in the action. Opening and funding your accounts with&nbsp;
                    <a className="text-red-500" href="https://bit.ly/BetOnlineSA" rel="noreferrer" target="_blank">BetOnline</a>
                    &nbsp;and&nbsp;
                    <a className="text-red-500" href="https://bit.ly/MyBookieSA" rel="noreferrer" target="_blank">MyBookie.</a>
                    &nbsp;is fast, secure, and easy. In just 10 minutes, you can be ready to shop for the best spreads and payouts, ensuring that every bet you place is optimized for maximum returns.
                  </div>
                  <br />
                  <div className="text-[#3cdf1f]">
                    <p className="sm:text-2xl text-lg font-semibold">Ready to Win More?</p>
                    Don’t wait—start now and take control of your betting strategy. Open your accounts, compare the odds, and watch your winnings soar.
                  </div>
                </div>
                <div className="w-full mt-4">
                  <a
                    href="https://record.betonlineaffiliates.ag/_-upPomnA17woAmwrkE6KlGNd7ZgqdRLk/1/"
                    target="_blank"
                    className="w-full"
                    rel="noreferrer"
                  >
                    <img src="/imgs/BetOnlineBaseball.jpg" alt="split action" className="mx-auto my-2" />
                  </a>
                  <a
                    href="https://record.webpartners.co/_HFx3XJRH2msWqcfzuvZcQGNd7ZgqdRLk/1/"
                    target="_blank"
                    className="w-full mt-2"
                    rel="noreferrer"
                  >
                    <img src="/imgs/MyBookie.jpg" alt="split action" className="mx-auto" />
                  </a>
                </div>
              </div>
              <div className="md:w-[72%] mx-2">
                <div className="p-2 mt-4">
                  <p className="font-[500] sm:text-3xl text-xl">
                    Up Your Game on Sports Wagering
                  </p>
                  <p className="text-[15px] sm:text-[17px] mt-2">
                    What direction is the crowd going? Where is the “sharp” money? SplitAction provides free, easily understandable,
                    and valuable information to help you make smarter wagers and improve payouts.
                  </p>
                  {/* <p className="text-[15px] sm:text-[17px] mt-2">
                    SplitAction merges consensus data from several sources to provide a more objective perspective on betting splits.
                    Obtaining data from a single source can be biased based upon marketing and / or states of operation.
                  </p> */}
                </div>
                
                {/* <a
                  href={configData?.middleBanners[0].href}
                  target="_blank"
                  className="flex text-center px-2 justify-center h-fit my-2 rounded-lg w-full cursor-pointer relative"
                >
                  <img
                    src={configData?.middleBanners[0].src}
                    className=""
                    alt=""
                  />
                </a>
                <a
                  href={configData?.middleBanners[1].href}
                  target="_blank"
                  className="flex text-center px-2 justify-center h-fit mt-4 rounded-lg min-w-56 w-full cursor-pointer relative"
                >
                  <img
                    src={configData?.middleBanners[1].src}
                    className="rounded sm:rounded-2xl"
                    alt=""
                  />
                </a> */}
                <hr className="mx-2 text-gray-400" />
                <div className="text-white mt-6 p-2 rounded text-sm sm:text-base text-justify">
                  <p className="font-[500] sm:text-3xl text-xl text-[#3cdf1f]">
                    How to Use SplitAction
                  </p>
                  SplitAction provides valuable sports wagering consensus data to utilize along with your other sports analysis.
                  <br />
                  <br />
                  When is it best to wager with or against the public (betting count) or whales (large dollar volume)?
                  <br />
                  <br />
                  The public commonly makes sports picks based upon overwhelming favorites, favorite team,
                  most recent performance, home team advantage, social media and / or biased by favorite players.
                  Also, teams with larger fan base may also skew the wagering odds (think New York Yankees vs Tampa Devil Rays).
                  Frequently, the public will make the wagers based upon “feeling” while ignoring facts in opposition.
                  As result, the lines commonly over-react to the wagering activity and adjust from the line open to game-time.
                  An observant and patient bettor can use this information to get more favorable odds as sportsbooks typically
                  adjust their lines in attempt to encourage more bettors to take opposing positions to balance out the bettors.
                  <br />
                  <br />
                  Wagering against the public (aka “fading”) is popular strategy as the theory is “the public is always wrong”.
                  While there is some evidence to support the public’s poor betting record (sportsbooks would not exist if the public were consistently accurate),
                  it is not always the case and each sporting event should be evaluated under its own conditions as many other factors can affect the outcome.
                  Injuries, matchups, change in lineups, weather, and unpredictable plays can all play a factor into the final score.
                  Based upon your analysis, If you wish to wager with the public – it might be best to lock-in that wager early. However,
                  if “fading” the public your patience may be rewarded with better odds / payout, if you wait closer to gametime.
                  <br />
                  <br />
                  The general public prefers to bet on favorites and high scoring events – afterall, its more exciting…..who wants to watch a boring game?
                  The lesser popular wager is to expect the underdog to play a close game or upset. Use consensus, along with your other analysis, to find good-value wagers.
                  <br />
                  <br />
                  Pay attention to the moneyline consensus vs spread consensus. “Sharp” bettors may be influencing the spread.
                  If the line moves in opposition of the expected change in odds and / or moves quickly, it typically due to a large wager(s).
                  Take this into consideration. Also, check other sportsbooks to see if you can capture a line that has not quickly reflected these changes in line movement.
                  <br />
                  <br />
                  Profitable sports bettors look for good value opportunities in wagers and use a variety of data to make their decisions.
                  SplitAction provides some of the data critical to this decision process.
                  SplitAction provides consensus data in a clean table to filter and download for future analysis
                  while other sites present this data in a confusing format. Again, use this data as you deem appropriate with your other analysis.
                  <br />
                  <br />
                  Good luck and stay in-the-green.
                </div>
              </div>
            </div>
            <div className="sm-full md:w-3/4 float-right lg:w-[370px] lg:mx-6 lg:block grid lg:grid-cols-1 grid-cols-2 text-[#aaa] mt-8 relative z-[10]">
              {configData?.rightBanners?.map((val, ind) => (
                <div className="sm:h-[145px] sm:flex items-center justify-center" key={ind}>
                  <a
                    href={val.href}
                    target="_blank"
                    className="sm:flex items-center mx-2 h-fit rounded-lg min-w-56 w-full cursor-pointer md:flex no-underline"
                  >
                    <img
                      src={val?.src}
                      className="rounded-2xl aspect-square mx-auto sm:w-[125px] sm:h-[125px]"
                      alt=""
                    />
                    <p className="p-2 mb-0 md:p-4 text-xs sm:text-sm text-justify text-[#aaa] sm:w-[calc(100%-130px)]">
                      {val?.text}
                    </p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        {!isLoading && (
          <>
            <div className="w-full justify-center text-white text-justify">
              <div className="p-4 md:w-1/2 mx-auto">
                <p className="text-2xl sm:text-3xl text-center opacity-0">.</p>
                <p className="border border-[#3cdf1f] p-3 text-[13px] sm:text-[15px]">
                  <p className="text-xl sm:text-2xl text-center">Disclaimer</p>
                  SplitAction is not a sportsbook operator but rather an informational site regarding sports betting for entertainment purposes.
                  Sports wagering laws vary by situs and change continuously.
                  SplitAction does not provide any information as to the legalities in your location.
                  Users of data and advertising links are strongly suggested to make their own inquiry into the legality of using this information.
                  SplitAction assumes no liability for the usage of the data, nor the products and services offered its advertising links.
                  The vetted vendors of the products and services offered by SplitAction are proven to be reliable and trustworthy.
                  SplitAction may be supported by the vendors. Vendors will periodically change.
                </p>
              </div>
            </div>
            <div className="bg-gradient-to-r from-[#3cdf1f] to-gray-900 h-10 w-full text-white rounded mt-5"></div>
            <div className="mt-5">
              <div className="w-full justify-center text-white from-[#3cdf1f] to-gray-900 text-white rounded">
                <div className="sm:text-base py-2 text-sm p-4 mx-auto text-left sm:w-2/3 text-yellow-200 text-center">
                  <p>Wager responsibly.</p>
                  Any tips, suggestions or comments for SplitAction, email us at{" "}
                  <a href="#" className="text-red-500">
                    support@splitaction.com
                  </a>
                  <p className="mt-4">Gambling Problem?? 1-800-GAMBLER.</p>
                  <p>
                    Published by SplitAction @Copyright2023 All rights
                    reserved.
                  </p>
                  <p>
                    Designated branding and trademarks by SplitAction and its
                    vendors are the property of their respective owners.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default HowToUse;